import axios from "axios";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Constants } from "../../../Constants";
import { Constants as ContentConstant } from "../../chatbot_kit/dialog_flowchart/Content";
import { IChatlog } from "../../../model";
import "./GetConversation.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { debounce } from "lodash";

const formatDateTime = (dateTimeString : string) => {
  const date = new Date(dateTimeString);
  
  if (isNaN(date.getTime())) {
    return ''; 
  }

  // Format date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  
  // Format time
  let hours = date.getHours();
  const amPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  
  return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${amPm}`;
};

export const ftimestr = (db_time:string)=>{
  if (db_time.length < 20) return "";
  const s = db_time.split("T");
  return `${s[0]}, ${s[1].substring(0,8)}`
}


// add request interceptor to add authorization header
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tbau");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const GetConversation = () => {
  const [chatlog, setChatlog] = useState<IChatlog[]>([]);
  const [pgNumber, setPgNumber] = useState<number>(1);
  const [pgLimit, setPgLimit] = useState<number>(20);
  const [pg, setPg] = useState(1);
  const re = /nodes\.\d+/;

  const setChatlogwithLimt = (pgLimit: number) => {
    axios
      .get<{ count: number }>(Constants.URL_ADMINGETCHATLOGCOUNT)
      .then((res) => {
        const count = res.data.count;
        // count is the number of row coming from the db
        // pgNumber is the number count in pagination
        // pgLimit is the total number of row per page
        if (count % pgLimit == 0) setPgNumber(count / pgLimit);
        else setPgNumber(Math.ceil(count / pgLimit));
        console.log("this is count", res);
        axios
          .get<IChatlog[]>(
            Constants.URL_ADMINGETALLCHATLOG + "?limit=" + pgLimit
          )
          .then((res) => {
            res.data.forEach((row) => {
              row.datetime_entered = new Date(
                row.datetime_entered
              ).toLocaleString();
            });
            setChatlog(res.data);
          });
      });
  };

  const debounceLoad = useCallback(
    debounce((pgLimit: number) => {
      setChatlogwithLimt(pgLimit);
    }, 800),
    []
  );

  useEffect(() => {
    setChatlogwithLimt(pgLimit);
  }, []);

  const paginationHandler = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    axios
      .get<IChatlog[]>(
        Constants.URL_ADMINGETALLCHATLOG +
          `?limit=${pgLimit}&offset=${(page - 1) * pgLimit}`
      )
      .then((res) => {
        res.data.forEach((row) => {
          row.datetime_entered = new Date(
            row.datetime_entered
          ).toLocaleString();
        });
        setChatlog(res.data);
        setPg(page);
      });
  };

  const rowNoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPg(1);
    if (+e.target.value < 0) {
      debounceLoad(0);
      setPgLimit(0);
    } else {
      debounceLoad(+e.target.value);
      setPgLimit(+e.target.value);
    }
  };

  return (
    <>
      <h3>ChatBot ChatLog</h3>
      <div className="rowNo">
        <label htmlFor="rowno">Number of Row Per Page: </label>
        <input
          id="rowno"
          type="number"
          value={pgLimit}
          required
          onChange={rowNoHandler}
        />
      </div>
      <table className="chatlog">
        <thead>
          <tr>
            <th>datetime</th>
            <th>user input</th>
            <th>bot reply</th>
            <th>Node Definition</th>
          </tr>
        </thead>
        <tbody>
          {chatlog.map((row) => {
            return (
              <tr key={row.id}>
                <td>{row.datetime_entered}</td>
                <td>{row.userinput}</td>
                <td>{row.botreply}</td>
                <td>
                  {re.test(row.botreply)
                    ? ContentConstant.NODES[
                        parseInt(
                          row.botreply
                            .match(re)
                            ?.find((x) => x)
                            ?.split(".")[1] ?? "0"
                        )
                      ].title
                    : "none"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Stack spacing={2}>
        <Pagination
          count={pgNumber}
          variant="outlined"
          shape="rounded"
          page={pg}
          onChange={paginationHandler}
        />
      </Stack>
    </>
  );
};

export default GetConversation;
