import Header from "../components/header/Header";
import "./ChatBotRoot.css";
import BottomTab from "../components/bottom_tab/BottomTab";
import { Outlet, Link } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import store, { RootState } from "../store/indexStore";
import { chatbotSlice } from "../store/chatbot";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { Constants } from "../Constants";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth:240,
  width:"70vw",
  maxWidth: 400,
  maxHeight:"80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow:"auto"
};

// use for replacing <a> to Link so that request is sent to client instead of server
// somehow instanceof Element always return false, so have to set it domNode to any for it to work
const options: HTMLReactParserOptions = {
  replace(domNode: any) {
    if (domNode.name === "a") {
      return (
        <Link to={domNode.attribs.href} target="_blank">
          {domNode.children[0].data}
        </Link>
      );
    }
  },
};

const ChatBotRoot = () => {
  const modal_state = useSelector((state: RootState) => state.chatbot);
  const handleClose = () => {
    store.dispatch(
      chatbotSlice.actions.setModal({
        ...modal_state,
        modalOn: false,
      })
    );
  };

  return (
    <div className="containerChatBotRoot">
      <Header htitle={Constants.HEADER_TOOTHBUDDY} />
      <div className="content-holder">
        <Outlet />
        <Modal
          open={modal_state.modalOn}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <b>Recommendation</b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {modal_state.node >= 0
                ? parse(modal_state.content, options)
                : ""}
            </Typography>
          </Box>
        </Modal>
      </div>
      <BottomTab />
    </div>
  );
};

export default ChatBotRoot;
